import React from 'react';
import { Link } from 'react-router-dom';

import scrollToTop from '@/helper/scrollToTop';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Check } from 'lucide-react';
import { Button } from '@/components/ui/button';

const ButtonStartNow= () => {
    return (
        <Link to="/database/demo">
            <Button variant="outline" className="w-full border-1 border-gray-900 rounded-3xl hover:rounded-xl mb-4" onClick={scrollToTop}>
                Start Now
            </Button>
        </Link>
    )
};

const ButtonContact= () => {
    
    return (
        <Link to="/contact-us">
            <Button variant="outline" className="w-full border-1 border-gray-900 rounded-3xl hover:rounded-xl" onClick={scrollToTop}>
                Talk with our team
            </Button>
        </Link>
    )
};

const PricingCard = ({ title, benefits, footer }) => (
    <Card className="h-full max-w-screen-sm grid grid-rows-[auto,1fr,auto]">
        
        <CardHeader className="w-full mt-0">
          <CardTitle className="">{title}</CardTitle>
        </CardHeader>

        <CardContent className="w-full mt-0">
            {benefits.map((benefit, index) => (
            <p key={index} className="flex">
                <Check className="h-5 w-5 ml-0 mr-1 mt-1 flex-none" />
                <span className="text-basis text-left mx-1">{benefit}</span>
            </p>
            ))}
        </CardContent>
        
        <CardFooter className="w-full mb-0 py-4">{footer}</CardFooter>
        
    </Card>
);

const PricingFree = () => {
  const benefits = [
    "Limited access to the Accretion database.",
    "Access to Accretion AI that is best for title work.",
    "Limited access to the Accretion beta products, including features like generating a title report.",
    "Get started now or book your free demo call with the Accretion team today.",
  ];

  return (
    <PricingCard
        title="Free"
        benefits={benefits}
        footer={
            <div>
                <ButtonStartNow/>
                <ButtonContact/>
            </div>
        }
    />
  );
};

const PricingConsult = () => {
  const benefits = [
    "Accretion will provide top database/AI engineers onsite to work closely with your company for one week.",
    "During the week, we will assess your company AI needs to enhance efficiency.",
    "We will develop a full-stack action plan to enhance efficiency, from creating a database solution to making your data AI-ready, to fine-tuning the Accretion AI model tailored specifically for your enterprise.",
  ];

  return (
    <PricingCard
      title="On-site Consultation"
      benefits={benefits}
      footer={
        <div>
        <div className="text-basis mb-4">
          <strong>$5,000,</strong> which can be used for our full-stack service computing credits.
        </div>
        <ButtonContact/>
        </div>
      }
    />
  );
};

const PricingFullStack = () => {
  const benefits = [
    "Full access to the latest Accretion AI models and database storage, to enhance your enterprise efficiency.",
    "Following the consultation, Accretion will build the best AI automation tools tailored exclusively for your company.",
    "Upgrade to Accretion's AI-ready database.",
    "Fine tune the Accretion AI models to meet your company's and market's needs.",
  ];

  return (
    <PricingCard
      title="Full-Stack Service"
      benefits={benefits}
      footer={
        <div>
        <div className="text-basis mb-4">
          <strong>$20 per month</strong> for full access to the standard solution.
          Contact sales for more information.          
        </div>
        <ButtonContact/>
        </div>
      }
    />
  );
};

const ExplorePlans = () => {
    
    return (
        <div className="max-w-screen-xl w-full h-full mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 py-4">
          
          <div className="h-full">
              <PricingFree/>
          </div>

          <div className="h-full ">
              <PricingConsult/>
          </div>

          <div className="h-full">
              <PricingFullStack/>
          </div> 

        </div>
    );
}

export { PricingFree, PricingConsult, PricingFullStack, ExplorePlans };