import { useState, useEffect } from 'react';
import { useAuth } from "@/user-auth/AuthContext.js";
import Loading from "@/database-demo/loading-error-handling/Loading.js";
import LoginOverlay from "@/user-auth/login/LoginOverlay.js";
import { DocumentColumn } from "@/helper/DocumentColumn";
import { Sidebar } from "@/components/custom-chat/ChatSidebar.js";
import { getData, getChatHistory, checkEmbeddingsAvailable } from "@/helper/getData";
import { ChatUI } from "@/components/custom-chat/Chat_HomeAI";

export default function HomeAI () {    
    const { isLoggedIn } = useAuth(); 
    const [data, setData] = useState(null);
    const [chats, setChats] = useState(null); 
    const [isEmbeddingsAvailable, setIsEmbeddingsAvailable] = useState(false);
    const [loading, setLoading] = useState(true);
    const propertyID="ChIJc6z-5M5w44kRCiQ_PBPMXKs";
    const addressInfo={
        "street_number": "22",
        "route": "Dell", 
        "locality": "Somerville",
        "unit": "",
        "state": "Massachusetts",
        "zipcode": "02145"
    };

    const address = "22 Dell St. Somerville MA";

    useEffect(() => {
        const fetchEmbeddingsStatus = async () => {
            const available = await checkEmbeddingsAvailable(propertyID);
            setIsEmbeddingsAvailable(available);
            setLoading(false);
        };

        const fetchData = async () => {
            const fetchedData = await getData({ addressInfo, propertyID });
            if (fetchedData) {
                setData(fetchedData); // Set data if it was found 
            }
        };

        const fetchChatHistory = async () => {
            const fetchedChatHistory = await getChatHistory(); 
            if (fetchedChatHistory) {
                setChats(fetchedChatHistory);
                // console.log("chats: ", fetchedChatHistory);
            }
        };
        
        fetchEmbeddingsStatus();
        fetchData();
        fetchChatHistory();
    }, [propertyID]);

    if (loading) {
        return(
            <div className="title-chat">
                <p>Loading documents from Accretion vector DB</p>
                <Loading/>
            </div>
        ) 
    }

    if (!isLoggedIn) {
        return (
            <LoginOverlay/>
        )
    }

    return (
        <div>            
            <div className="hidden md:block">
                <Sidebar chats={chats}/>
            </div>

            <div className="grid grid-cols-3 md:grid-cols-5 mr-4 ml-4 md:ml-12 gap-4">
                <div className="hidden md:block col-span-2 h-full w-full border-2 border-gray-400 rounded-3xl">
                    <DocumentColumn data={data} srcAudio={`${process.env.PUBLIC_URL}/audios/22-dell-street.wav`}/>
                </div>
                
                <div className="col-span-3 h-full w-full">
                    <ChatUI propertyID={propertyID} recentChats={data}/>
                </div>
            </div>
        </div>
    )
}