import { Button, VStack } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom'; 
import scrollToTop from "../../helper/scrollToTop";

const widthButton = "200px";

const buttonStyleSolid = {
    variant: "solid",
    color: "white", 
    bg: "gray.900",
    _hover: { color: 'white', bg: 'gray.700' },
    width: widthButton,
};

const ButtonTalkWithOurTeam = () => {
    const navigate = useNavigate();
    
    const handleClick = () => {
        scrollToTop();
        navigate('/contact-us'); // Navigate to the contact-us route
    };
    
    return (
        <VStack>
            <Button {...buttonStyleSolid} onClick={handleClick}>
                Talk with our team
            </Button>
        </VStack> 
    )
}

const buttonStyleOutline = {
    variant: "outline",
    color: "black", 
    border: "solid black",
    padding: "10px", 
    width: widthButton,
    _hover: { color: 'black', bg: 'gray.200' },
};

const ButtonStartNow = () => {
    const navigate = useNavigate();
    
    const handleClick = () => {
        scrollToTop();
        navigate('/database/demo'); // Navigate to the contact-us route
    };

    return (
        <VStack>
            <Button {...buttonStyleOutline} onClick={handleClick}>
                Start now
            </Button>
        </VStack>
    )
}

export  { ButtonTalkWithOurTeam, ButtonStartNow };