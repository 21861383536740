import { Button } from "react-bootstrap";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { CustomOverlay } from "../overlays/Overlays";

import ImageLoading from "../../helper/ImageLoading";

import { AiFillMessage } from "react-icons/ai";
import { MdEditDocument, MdUpdate } from "react-icons/md";
import { Icon } from "@chakra-ui/react";
import { FiShare, FiPlusCircle } from "react-icons/fi";
import { GrEdit } from "react-icons/gr";

import createQueryString_title_report from "../../helper/createQueryString_title_report";
import scrollToTop from "../../helper/scrollToTop";

import "./Buttons.css";

const ButtonTalkWithOurTeam = () => {
    return (
        <Link to="/contact-us">
        <Button 
            variant="outline-primary" 
            id="button-talk-with-our-team"
            onClick={scrollToTop}
        >
            Talk with our AI team 
        </Button>
        </Link>
    )
}

const ButtonTryAccretionAIToday = () => {
    return (
        <Link to="/database/demo">
            <Button 
                variant="outline-primary" 
                id="button-try-accretion-ai-today"
                onClick={scrollToTop}
            >
                Try Accretion AI today
            </Button>
        </Link>
    )
}

const ButtonRecomm = ({ addressInfo, onClick }) => {
    return (
        <Button
            onClick={onClick}
            variant="outline-primary"
            id="button-recomm"
            style={{ maxWidth: "300px" }}
        >
            {addressInfo.street_number} {addressInfo.route}
            <br />
            {addressInfo.locality}
            <br />
            {addressInfo.state} {addressInfo.zipcode}
        </Button>
    );
};

const ButtonTitleChat = ({ propertyID, addressInfo }) => {       
    const address = `${addressInfo.street_number} ${addressInfo.route} ${addressInfo.locality} ${addressInfo.state} ${addressInfo.zipcode}`    
    const queryParams = new URLSearchParams({
        propertyid: propertyID,
        address: address,
    });

    // Handle button click
    const navigate = useNavigate(); 
    const handleClick = () => {
        navigate(`/title-chat/?${queryParams.toString()}`);
    };

    return (        
        <Button 
            variant='outline-primary' 
            id='button-title-rag' 
            onClick={handleClick}
        >
            <Icon as={AiFillMessage}/>
            Talk With This Title
        </Button>        
    )
}

const ButtonTitleReport = ({ addressInfo, propertyID }) => {
    const queryParams = new URLSearchParams({
        propertyid: propertyID,
        addressinfo: JSON.stringify(addressInfo),        
    });
    const navigate = useNavigate(); 
    const handleClick = () => {
        navigate(`/title-report/?${queryParams.toString()}`);
    };

    return (
        <Button                
            variant='outline-primary' 
            id='button-title-rag'
            onClick={handleClick}
        >
            <Icon as={MdEditDocument}/>
            Generate Title Report
        </Button>
    )
}

const ButtonShare = ({ shareLink, linkPNG, dataPNG }) => {
    const [isOpen, setIsOpen] = useState(false); // Control for CustomOverlay
    const [copied, setCopied] = useState(false);

    // Open and close overlay
    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    // Copy link to clipboard function
    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareLink)
        .then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 1000); // Reset copied state after 1 second
            console.log("Link copied to clipboard!");
        })
        .catch((err) => {
            console.error("Failed to copy the link: ", err);
        });
    };

    return (
        <>
        <Button 
            variant='outline-primary' 
            id='button-share'
            onClick={onOpen}
        >
            <Icon as={FiShare}/>
            Share
        </Button> 
        <CustomOverlay isOpen={isOpen} onClose={onClose}>
            <div className="row" style={{margin:"auto"}}>
                <div className="column">
                    {dataPNG ? (
                        <img src={dataPNG} alt="Deed Visual" />
                    ) : (
                        <ImageLoading imageUrl={linkPNG} altText="Static deed visual" />
                    )}
                </div>
            </div>
            <div className="row" style={{ marginTop: "5svh", margin:"auto" }}>
                <div className="column" style={{ flex: "5" }}>
                    <input 
                        type="text" 
                        value={shareLink} 
                        readOnly 
                        id="input-share-link" 
                        style={{ width: "100%" }}
                    />
                </div>
                <div className="column" style={{ flex: "1" }}>
                    <Button
                        onClick={copyToClipboard}
                        variant="outline-primary"
                        id="button-copy-link"
                    >
                        Copy
                    </Button>
                    {copied && (
                        <div className="copy-success-message">
                            Link copied to clipboard!
                        </div>
                    )}
                </div>
            </div>
        </CustomOverlay>
        </>
    )

}

const ButtonSave = ({ propertyID }) => {
    const urlBackendSaveProperty = process.env.REACT_APP_BACKEND_DOMAIN + "/api/user-data/save-property/";

    const handleSave = async () => {        
        try {
            // Get CSRF token from cookies
            const csrftoken = document.cookie.split('; ').find(row => row.startsWith('csrftoken=')).split('=')[1];
        
            const response = await fetch(urlBackendSaveProperty, {
                method: 'POST',
                credentials: 'include', // Ensure cookies are sent with the request 
                headers: {
                    'Content-Type': 'application/json',      
                    'X-CSRFToken': csrftoken,               
                },
                body: JSON.stringify({ "propertyID": propertyID })
            });

            if (response.ok) {
                const result = await response.json();
                console.log(result);                
            } else {
                const errorData = await response.json();
                console.error(errorData.error || "An error occurred while saving the property.");
            }
        } catch (error) {
            console.error("An error occurred while saving the property.");
        } 
    };

    return (
        <Button 
            variant="outline-primary" 
            onClick={handleSave}
            id="button-share"
        >   
            <Icon as={FiPlusCircle}/>
            Save
        </Button>
    )
};

const ButtonEdit = () => {
    
    return (        
        <Button             
            variant="outline-primary" 
            id="button-share"
        >
            <Icon as={GrEdit}/> 
            Edit 
        </Button>
    )
}

const ButtonCheckUpdate = ({ propertyID, addressInfo }) => {
    const urlCheckUpdate = process.env.REACT_APP_BACKEND_DOMAIN + "/api/doc-retrieval/check-update/";    
    // Control for CustomOverlay
    const [isOpen, setIsOpen] = useState(false); 
    // Open and close overlay    
    const onClose = () => setIsOpen(false);
    
    const checkUpdate = async () => {        
        const queryString = createQueryString_title_report({ addressInfo, propertyID });

        try {                    
            const response = await fetch(urlCheckUpdate + queryString, {
                method: 'GET',                                
            });

            if (response.ok) {                 
                if (response.status === 202) {
                    console.log("check update in progress"); 
                    setIsOpen(true)
                }        
            } else {
                const errorData = await response.json();
                console.error(errorData.error || "An error occurred while checking update. ");
            }
        } catch (error) {
            console.error("An error occurred while checking update.");
        } 
    };

    return (
        <>
            <Button 
                variant="outline-primary" 
                onClick={checkUpdate}
                id="button-share"
            >   
                <Icon as={MdUpdate}/>
                Check Update 
            </Button>
            <CustomOverlay isOpen={isOpen} onClose={onClose}>                
                <p style={{textAlign:"center"}}> Checking Updates... </p>                
                <ImageLoading/>
            </CustomOverlay>
        </>
    )
};

export { ButtonTalkWithOurTeam, ButtonTryAccretionAIToday, ButtonRecomm, ButtonTitleChat, ButtonTitleReport, ButtonShare, ButtonSave, ButtonEdit, ButtonCheckUpdate };