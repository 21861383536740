import { useEffect } from "react";  
import createQueryString_db_visual from "../../helper/createQueryString_db_visual";
import createQueryString_title_report from "../../helper/createQueryString_title_report";
import isV2Avail from "../../helper/isV2Avail";

import "../DatabaseDemo.css";


// API URL for get ATTOM json data from backend
const url_accretionDB_getData_v1 = process.env.REACT_APP_BACKEND_DOMAIN + "/api/database-visualization/get-data/";
// API URL for get v2 json data from backend
// const url_accretionDB_getData_v2 = process.env.REACT_APP_BACKEND_DOMAIN + "/api/doc-retrieval/json-retrieval/";
const url_accretionDB_getData_v2 = process.env.REACT_APP_BACKEND_DOMAIN + "/api/doc-retrieval/doc-retrieval/";
// API endpoint for checking task status of v2 json data retrieval 
const url_accretionDB_task_status = process.env.REACT_APP_BACKEND_DOMAIN + "/api/doc-retrieval/task-status/";
// URL for Database share 
const url_database_share = process.env.REACT_APP_HOST_DOMAIN + "/database/demo/share/"; 

export default function DatabaseFetchAccretionDB({ 
    addressInfo, 
    setFetchStatus, 
    setResponseStatus, 
    setDataJSON, 
    setShareLink, 
    setV2Avail,    
    propertyID,     
}) {
    
    const getDataHandler_v1 = async (event) => { //API call to get data from accretion-backend v1
        if (event) {
            event.preventDefault();
        }

        if (!addressInfo) {
            console.error("DatabaseFetch: addressInfo is null");
            return null;
        }
        
        const queryString = createQueryString_db_visual({ addressInfo });
        setShareLink(url_database_share + queryString); 
        
        if (queryString === null) {            
            console.error("DatabaseFetch: queryString is null");
            return null;
        }
        
        try {
            console.log("DatabaseFetch: Make API call to AccretionDB v1...");
            const response = await fetch(
                url_accretionDB_getData_v1 + 
                queryString, 
                {
                    method: "GET",                 
                }
            );
            console.log("DatabaseFetch: Complete API call to AccretionDB")
            if (response.ok) {
                const data = await response.json();
                setFetchStatus(true);
                setDataJSON(data);                                 
                setResponseStatus(true);                 
            } else {
                console.error("Failed to fetch data: ", response.statusText); 
                setFetchStatus(false); 
                setResponseStatus(true);
            }
        }
        catch (error) {
            console.error("Error during fetch: ", error);            
            setFetchStatus(false);     
            setResponseStatus(true);       
        }
    }

    const getDataHandler_v2 = async (event) => { //API call to get data from accretion-backend v2
        if (event) {
            event.preventDefault();
        }

        if (!addressInfo) {
            console.error("DatabaseFetch: addressInfo is null");
            return null;
        }

        const queryString = createQueryString_title_report({ addressInfo, propertyID });
        setShareLink(url_database_share + queryString); 
        
        if (queryString === null) {            
            console.error("DatabaseFetch: queryString is null");
            return null;
        }
        
        try {
            console.log("DatabaseFetch: Make API call to AccretionDB v2...");
            // console.log(url_accretionDB_getData_v2 + queryString)
            const response = await fetch(
                url_accretionDB_getData_v2 + 
                queryString, 
                {
                    method: "GET",
                }
            );
            console.log("DatabaseFetch: Complete API call to AccretionDB v2")
            if (response.ok) {
                // console.log(response);
                const data = await response.json();
                if (response.status === '202') {
                    console.log("retrieving in progress");  
                    // console.log(data);
                    startPolling(data.task_id);
                } else {
                    // console.log(data.data);
                    if (data.data[0].type === "not found") { //no records found in the county website
                        setFetchStatus(false); 
                        setResponseStatus(true); 
                        return 
                    }
                    setFetchStatus(true);
                    // console.log("DatabaseFetchAccretionDB data: ", data);
                    setDataJSON(data);
                    setResponseStatus(true);
                }                
                               
            } else {
                console.error("Failed to fetch data: ", response.statusText); 
                setFetchStatus(false); 
                setResponseStatus(true);
            }
        }
        catch (error) {
            console.error("Error during fetch: ", error);            
            setFetchStatus(false);     
            setResponseStatus(true);       
        }
    }

    // polling to check if celery task is finished
    const startPolling = (taskId) => {
        const pollingInterval = setInterval(async () => {
            try {
                const response = await fetch(
                    url_accretionDB_task_status + `${taskId}/`, 
                    {
                        method: 'GET',
                    }
                );
                if (response.ok) {
                    const data = await response.json();
                    console.log('Task status:', data);
                    if (data.status === 'SUCCESS') {
                        clearInterval(pollingInterval); // Stop polling
                        getDataHandler_v2(); // Get the retrieved data from backend 
                    } else if (data.status === 'FAILURE') {
                        console.error('Task failed');
                        setFetchStatus(false); 
                        setResponseStatus(true);                    
                        clearInterval(pollingInterval); // Stop polling
                    }
                }
            } catch (error) {
                console.error('Error fetching task status:', error);
            }
        }, 10000); // Poll every 10 seconds
    };

    // trigger getDataHandler when the addressInfo change 
    useEffect(() => {
        // console.log("addressInfo:", addressInfo)
        if (addressInfo) { 
            const newV2Avail = isV2Avail({ addressInfo });
            console.log("newV2Avail", newV2Avail);
            setV2Avail(newV2Avail);
            if (newV2Avail) {
                getDataHandler_v2();
            } else {
                // getDataHandler_v1();
                setFetchStatus(false);     
                setResponseStatus(true);   
                // return null; 
            }            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressInfo]);

    return null;
};