import { useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api'; 
import React, { useState, useCallback, useEffect } from 'react';
// import Button from 'react-bootstrap/Button';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import { FaSearch } from "react-icons/fa";
import Loading from '../loading-error-handling/Loading';

import '../DatabaseDemo.css';

const API_key_google_maps = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; 

const libraries = ['places'];

const MapAutocomplete = ({ 
  addressInfo, 
  setAddressInfo, 
  setResponseStatus, 
  setDataPNG, 
  setDataJSON, 
  setFetchStatus, 
  setPropertyID, 
  setShowOverlay,
}) => {
  // searchBox state is reference to StandAloneSearchBox 
  const [searchBox, setSearchBox] = useState(null);  
  const [newPropertyID, setNewPropertyID] = useState(null); 

  // states for getting new address from Google Maps API
  const [newAddressInfo, setNewAddressInfo] = useState({
    "street_number": "",
    "route": "", 
    "locality": "",
    "unit": "",
    "state": "",
    "zipcode": ""
  }); 

  const updateNewAddressInfo = (key, val) => {
    setNewAddressInfo(prevState => ({
      ...prevState, 
      [key]: val
    }));
  }
  
  const [unit, setUnit] = useState('');

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: API_key_google_maps,
    libraries,
  });
  
  // StandAloneSearchBox passes its reference to onLoad 
  const onLoad = (ref) => {    
    if (ref) {
      const usaBounds = {
        east: -66.93457,
        west: -124.831,
        north: 49.384358,
        south: 24.396308,
      };
      ref.setBounds(usaBounds);
      setSearchBox(ref);      
    } else {
      console.error("MapAutoComplete: onLoad ref not defined");
    }   
  };

  const onPlacesChanged = useCallback(() => {
    if (!searchBox) {
      console.error("onPlacesChanged: searchbox ref is null");
      return;
    }
    if (typeof searchBox.getPlaces !== 'function') {
      console.error("searchBox.getPlaces is not a function.");
      return;
    }
    const places = searchBox.getPlaces();
    if (places.length > 0) {                  
      if (places && places.length > 0) {
        const place = places[0];                
        if (place && place.address_components) {               
          if (place.place_id) {            
            setNewPropertyID(place.place_id);
          }
          place.address_components.forEach(component => {
            if (component.types.includes('street_number')) {          
              updateNewAddressInfo("street_number", component.long_name); 
            } else if (component.types.includes('route')) {
              updateNewAddressInfo("route", component.long_name); 
            } else if (component.types.includes('locality')) {
              updateNewAddressInfo("locality", component.long_name); 
            } else if (component.types.includes('administrative_area_level_1')) {
              updateNewAddressInfo("state", component.long_name); 
            } else if (component.types.includes('postal_code')) {
              updateNewAddressInfo("zipcode", component.long_name); 
            } 
          });          
        }     
      }
    }
  },[searchBox]);


  const submitSearch = () => {      
    if (unit) {
      updateNewAddressInfo("unit", unit);      
      setPropertyID(newPropertyID + "-unit" + unit);
    } else {
      setPropertyID(newPropertyID); 
    }    

    if (JSON.stringify(newAddressInfo) !== JSON.stringify(addressInfo)) {
      if (Object.values(newAddressInfo).every(value => value === "")) {
        console.warn("MapAutoComplete: search field is empty.");
        return 
      }
      setAddressInfo(newAddressInfo);
      setResponseStatus(false);
      setFetchStatus(null);
      setDataPNG(null);
      setDataJSON(null);
    } else {
      // setShowOverlay(true);
      console.warn("MapAutoComplete: search button clicked, enter a new address for search.");      
    }
    // show the result in overlay 
    setShowOverlay(true);
  }

  const handleKeyStroke = (e) => {
    if (e.key === "Enter") {
      submitSearch();
    }
  }

  useEffect(() => {
    if (searchBox) {
      console.log('searchBox Ref is created');
    }
  }, [searchBox]);


  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  if (!isLoaded) {
    return <Loading/>;
  }


  return (
    
    <div className='max-w-screen-md w-full h-12 rounded-3xl flex px-2'>
      <div className='h-full flex-1'>
        <StandaloneSearchBox           
          onLoad={onLoad}
          onPlacesChanged={onPlacesChanged}        
          options={{
            types: ['address'],
            componentRestrictions: { country: 'us' },
            fields: ['address_components', 'geometry', 'icon', 'name'],
          }}                  
        >          
          <Input
            type="text"
            placeholder="Enter Address" 
            className='h-12 text-base rounded-l-3xl rounded-r-none border-1 border-r-0 border-gray-900 focus-visible:ring-offset-0 focus-visible:ring-0'
          />
        </StandaloneSearchBox>
      </div>                
      
      <div className='h-full flex-none'>
        <Button 
          variant='outline' 
          onClick={submitSearch}
          className="h-12 rounded-l-none rounded-r-3xl border-1 border-gray-900"
        > 
          <FaSearch/>
        </Button>
      </div>

      <style jsx global>{`
        .pac-container {
          z-index: 1000;                    
          border-radius: 0.75rem;            
          background-color: white; /* Ensure white background */
          margin-top: -80px;
          padding: 10px;
        }        
      `}</style>

    </div>    
  );
};

export default MapAutocomplete;
