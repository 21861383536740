import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/react';

const CustomOverlay = ({ isOpen, onClose, children }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="90svw" maxW="var(--max-width)" maxH="90svh">
        <ModalCloseButton />
        <ModalBody marginTop="30px" marginBottom="30px" >
            {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const PDFOverlay = ({ isOpen, onClose, children }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay className=''/>
      <ModalContent className="w-full h-full rounded-lg overflow-hidden max-w-5xl mx-auto my-12">
        <ModalCloseButton />
        <ModalBody className="flex justify-center items-center h-full">
            {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { CustomOverlay, PDFOverlay };