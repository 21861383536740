import { useState, useEffect } from 'react';

import MapAutocomplete from './google-map-api/MapAutocomplete.js'; 
import DatabaseFetchAccretionDB from './accretion-backend-api/DatabaseFetchAccretionDB.js'; // fetch data from accretion-backend
import Loading from './loading-error-handling/Loading.js';

import VisualizationCard_v3 from './visualization-card/VisualizationCard_v3.js';
import { CustomOverlay } from '../components/overlays/Overlays.js'; 
import { ButtonTalkWithOurTeam } from '../components/buttons/ButtonsChakra.js';

import Recommendation from '@/recommendation/Recommendation.js';

import "./DatabaseDemo.css"

export default function DatabaseSearch () {
    ////////// State Hooks //////////////

    // states for Google Maps API
    const [addressInfo, setAddressInfo] = useState(null); 
    // json data from backend API
    const [dataJSON, setDataJSON] = useState(null); 
    // responseStatus false if waiting for API to get back, true if get back from API
    const [responseStatus, setResponseStatus] = useState(false);
    // fetchStatus true if the API response is good, false if bad
    const [fetchStatus, setFetchStatus] = useState(null); 
    // dataPNG for deed visual static image
    const [dataPNG, setDataPNG] = useState(null); 
    // shareLink for sharing the deed visual 
    const [shareLink, setShareLink] = useState(null);    
    // propertyID: use google place ID as property ID for backend database 
    const [propertyID, setPropertyID] = useState(null); 
    // v2Avail: logic if the v2 backend service is available 
    const [v2Avail, setV2Avail] = useState(false);
    // open/close overlay 
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);  // Control for the overlay        

    // Overlay close function
    const closeOverlay = () => {        
        setIsOverlayOpen(false); 
    };

    // Automatically open overlay when the data is ready
    useEffect(() => {
        if (dataJSON && responseStatus && v2Avail) {
            setIsOverlayOpen(true);
        } else if (!v2Avail && addressInfo) {
            setIsOverlayOpen(true);  // Also open for the "Contact Us" section
        }
    }, [dataJSON, responseStatus, v2Avail, addressInfo]);
    
    const updateFetchStatus = (data) => {
        setFetchStatus(data); 
    }          

    return (
        <div className='max-w-screen-xl'>
            <div className='min-h-[30svh] grid grid-cols-1 my-32'>
                <div className='text-center'> 
                    <h1 className='font-bold md:text-5xl'> Search Accretion Database </h1>
                </div>  

                <div className='w-full text-center'> 
                    <MapAutocomplete addressInfo={addressInfo} 
                                    setAddressInfo={setAddressInfo} 
                                    setResponseStatus={setResponseStatus} 
                                    setDataPNG={setDataPNG} 
                                    setDataJSON={setDataJSON}
                                    setFetchStatus={setFetchStatus}
                                    setPropertyID={setPropertyID}
                                    setShowOverlay={setIsOverlayOpen}                                
                    />                                                
                </div> 
            </div>            

            {addressInfo && ( 
                <div>                     
                    <div>
                        <DatabaseFetchAccretionDB   addressInfo={addressInfo} 
                                                    setFetchStatus={updateFetchStatus} 
                                                    setResponseStatus={setResponseStatus} 
                                                    setDataJSON={setDataJSON} 
                                                    setShareLink={setShareLink}
                                                    setV2Avail={setV2Avail}                                                    
                                                    propertyID={propertyID}                                                    
                        />                        
                    </div>        
                </div>
            )}
            {/* V2: direct county fetch and visual */}
            <CustomOverlay isOpen={isOverlayOpen} onClose={closeOverlay}>
                {(v2Avail && dataJSON && responseStatus) && (
                    <VisualizationCard_v3
                        dataJSON={dataJSON}
                    />
                )}
                {(v2Avail && responseStatus === false) && (
                    <div>
                        <p>Loading data from AccretionDB </p>
                        <Loading/>
                    </div> 
                )} 
                {(v2Avail && responseStatus === true && fetchStatus === false) && (
                    <div>
                        <p>We are collecting data for you. Check back later... </p>
                        <Loading/>
                    </div>
                )}
                {!v2Avail && addressInfo && (
                    <div className='row'>
                        <p style={{ padding: "5px 10svw", textAlign: "left", fontSize: "17px" }}>
                            We have located your property in {addressInfo.locality}, {addressInfo.state} in our database.
                            <br />
                            We are expanding Accretion AI services to you as fast as we can.
                            <br />
                            Meanwhile, we invite you to try our AI services on properties in Middlesex County, Massachusetts first.
                        </p>
                        <div className='row'>
                            <ButtonTalkWithOurTeam/>
                        </div>                        
                    </div>
                )}
            </CustomOverlay>          
        </div>
    )
}