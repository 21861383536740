// VisualizationCard V3: use Chakra to create overlay 

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { ButtonTitleChat, ButtonTitleReport, ButtonShare, ButtonSave, ButtonCheckUpdate } from '../../components/buttons/Buttons';
import CreateTitleVisualD3 from '../d3-attom-demo/CreateTitleVisualD3';
import CreateTitleVisualPNG from '../d3-attom-demo/CreateTitleVisualPNG';

import createQueryString_title_report from '../../helper/createQueryString_title_report';
import Loading from '../loading-error-handling/Loading';

import '../DatabaseDemo.css';
import './VisualizationCard.css';

// URL for Database share 
const url_database_share = process.env.REACT_APP_HOST_DOMAIN + "/database/demo/share/"; 

const VisualizationCard_v3 = ({ dataJSON }) => {
    // dataPNG for deed visual static image
    const [dataPNG, setDataPNG] = useState(null); 
    // shareLink for sharing the deed visual 
    const [shareLink, setShareLink] = useState(null);    
    // linkPNG: link to the static deed visual from backend 
    const [linkPNG, setLinkPNG] = useState(null); 
    
    const [propertyID, setPropertyID] = useState(null);
    const [addressInfo, setAddressInfo] = useState(null);

    // Local state to manage data availability
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isDataValid, setIsDataValid] = useState(null); 

    useEffect(() => {
        // Check if data is available and set the loaded state
        if (dataJSON) {
            // console.log("visualization card v2: dataJSON: ", dataJSON)
            setIsDataLoaded(true);
            if ("visual card data", dataJSON.data[0].type === "not found") {
                setIsDataValid(false); 
            } else {
                setIsDataValid(true);
            }
            setPropertyID(dataJSON.propertyID);
            setAddressInfo(dataJSON.addressInfo);
            setShareLink(url_database_share + createQueryString_title_report({ addressInfo, propertyID })); 
        } 
    }, [dataJSON]);
    

    // Render loading indicator if data is not yet available
    if (!isDataLoaded) {        
        return <Loading />;
    } 

    if (isDataValid === false) {
        return (
            <div>
                <p> This Property Is Not Found On Public Records. </p>                 
                <p> Contact Our Support Team To Get the Latest Update. </p>                
                <Link to="/contact-us"> 
                    <Button variant='outline-primary' id='button-contact-us' > 
                        Contact Us
                    </Button>
                </Link>
            </div>
        )
    }

    return ( 
        <div className='visualization-card'>        
            <CreateTitleVisualD3 dataJson={dataJSON} addressInfo={addressInfo} propertyID={propertyID} />
            <CreateTitleVisualPNG dataJson={dataJSON} setDataPNG={setDataPNG} addressInfo={addressInfo} />
            <div className="share-save-edit">
                <div className='row'>
                    <ButtonTitleChat addressInfo={addressInfo} propertyID={propertyID}/>
                </div>
                <div className='row'>
                    <ButtonTitleReport addressInfo={addressInfo} propertyID={propertyID} />
                </div>
                <div className="row" > 
                    <div className="column" style={{ margin:"0" }} >
                        <ButtonShare shareLink={shareLink} linkPNG={linkPNG} dataPNG={dataPNG}/>                        
                    </div>
                    <div className="column" style={{ margin:"0" }} >                        
                        <ButtonSave propertyID={propertyID} />
                    </div> 
                    <div className="column" style={{ margin:"0" }} >                        
                        <ButtonCheckUpdate propertyID={propertyID} addressInfo={addressInfo} />
                    </div> 
                </div>
            </div>
        </div>
    );
};

export default VisualizationCard_v3;