
const createQueryString_getData = ({ propertyID }) => {
    
    if (!propertyID) {
        console.error("createQueryString_title_report: propertyID is null");
        return null;
    }

    // create encoded query string 
    const encodedQueryString =  `?propertyID=${propertyID}&addressInfo=${null}`;
    
    return (encodedQueryString)
};


const createQueryString_title_chat = (chat) => {        
    if (!chat) {
        console.error("createQueryString_title_report: chat is null");
        return null;
    }
    const address = chat.addressOneLine;
    const propertyID = chat.propertyID;
    if (!address) {
        console.error("createQueryString_title_report: addressInfo is null");
        return null;
    }

    if (!propertyID) {
        console.error("createQueryString_title_report: propertyID is null");
        return null;
    }

    // create encoded query string 
    const encodedQueryString =  `?propertyid=${propertyID}&address=${encodeURIComponent(JSON.stringify(address))}`;
    
    return (encodedQueryString)
}

export { createQueryString_getData, createQueryString_title_chat }