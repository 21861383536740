import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
// import { FormText } from 'react-bootstrap';

export default function RootLayout() {
    const location = useLocation();
    
    return (
        <div>
            <Header className=""/> 
            <main className="mt-24">
                < Outlet />
            </main>
            {
                (location.pathname !== '/title-chat/' && location.pathname !== '/home-ai/cottonwood')
                && <Footer />
            }
        </div>
    )

};