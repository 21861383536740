
import { useAuth } from './AuthContext'; 
import { useGoogleLogin } from '@react-oauth/google';
import { useMsal } from "@azure/msal-react";

import { useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button"
import { FcGoogle } from "react-icons/fc";
import { FaMicrosoft } from "react-icons/fa";

import { Card, CardContent, CardTitle } from "@/components/ui/card";

import './user-auth.css';

// const clientIdMicrosoft = process.env.REACT_APP_MICROSOFT_CLIENT_ID;

const LoginCard = () => {    
    
    const { authGoogle, authMicrosoft } = useAuth(); 
    const { instance } = useMsal();    
    const navigate = useNavigate(); 

    // Capture the full current URL
    const fullPath = window.location.href;

    // Parse the URL to get the pathname and search parameters only
    const url = new URL(fullPath);
    const redirectPath = `${url.pathname}${url.search}`;

    const loginGoogle = useGoogleLogin({
        onSuccess: (response) => {
            // Handle successful authentication            
            const access_token = response.access_token; // Extract the token from response            
            const provider = "google";            
            // Verify token at the backend
            authGoogle(access_token, provider); 
            navigate(redirectPath);
        },
        onFailure: (error) => {
            // Handle errors
            console.error('Error:', error);
        }
    });

    const loginMicrosoft = async () => {    
        try {            
            const response = await instance.loginPopup({
                scopes: ["user.read"],
                prompt: "select_account"
            });
            if (response.accessToken) {
                // console.log("microsoft access token: ", response.accessToken)
                authMicrosoft(response.accessToken, "microsoft");
                navigate(redirectPath);
            }
        } catch (error) {
            console.error('Microsoft login error:', error);
        }
    };
  
    return (        
        <Card class="max-w-96 rounded-3xl border-2 border-gray-300 ">
            <CardTitle class="text-base text-center my-4"> Start your title work with Accretion AI. </CardTitle>
                <CardContent>
                    <Button 
                        onClick={() => loginGoogle()}
                        variant="outline" 
                        class="rounded-xl w-full flex p-2 my-2 items-center hover:rounded-3xl border-2 border-black "
                    >                                                                                   
                        <FcGoogle class="w-6 h-6 mx-4"/> Continue with Google 
                    </Button>  
                    <Button 
                        onClick={loginMicrosoft}
                        variant="outline" 
                        class="rounded-xl w-full flex p-2 my-2 items-center hover:rounded-3xl border-2 border-black "
                    >                                                                                   
                        <FaMicrosoft class="w-6 h-6 mx-4"/> Continue with Microsoft 
                    </Button>                   
                </CardContent>  
        </Card> 
    );
  };
  
export default LoginCard;
