import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react'; 
import { Button } from '@/components/ui/button';
import { FaBars } from 'react-icons/fa';

import logo from './logo_letters_v2.svg'; 

import { useAuth } from '../user-auth/AuthContext'; 

import {
    Sheet,
    // SheetClose,
    SheetContent,
    // SheetDescription,
    // SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
  } from "@/components/ui/sheet";


const ButtonSheet = () => {
    const [open, setOpen] = useState(false);

    const ButtonMobile = ({ buttonText, buttonRoute}) => {
        const navigate = useNavigate();

        const handleClick = () => {
            setOpen(false);
            navigate(buttonRoute);            
        }
        
        return (
            <Button 
                variant="ghost" 
                className="w-full py-8 text-lg font-bold justify-start hover:bg-inherit"
                onClick={handleClick}
            >
                {buttonText}
            </Button> 
        )
    }
    

    return (
        <Sheet open={open} onOpenChange={setOpen}>
          
          <SheetTrigger asChild>
            <Button variant="ghost" className="hover:bg-inherit w-6 p-0 m-0"><FaBars/></Button>
          </SheetTrigger>

          <SheetContent side="right" className="w-full sm:max-w-none">
            <SheetHeader className="h-20 ">
                <SheetTitle className="h-full">
                    <img src={logo} className='w-36 ml-2'/>
                </SheetTitle>              
            </SheetHeader>
            <div className="grid grid-cols-1 gap-0">
                <ButtonMobile buttonText="Database" buttonRoute="/database"/>                            
                <ButtonMobile buttonText="Intelligence" buttonRoute="/ai"/>
                <ButtonMobile buttonText="Company" buttonRoute="/company"/>
                <ButtonMobile buttonText="Contact us" buttonRoute="/contact-us"/>                
            </div>            

          </SheetContent>
        </Sheet>
      )
};

const ButtonHeader = ({ buttonText, buttonRoute }) => {
    const navigate = useNavigate();

    const handleClick = () => {        
        navigate(buttonRoute);            
    }

    return(
        <Button 
            variant="ghost"
            onClick={handleClick}
            className="rounded-3xl"
        >
            {buttonText}
        </Button>
    )
};

const ButtonUser = ({ buttonText, buttonRoute }) => {
    const navigate = useNavigate();

    const handleClick = () => {        
        navigate(buttonRoute);            
    }

    return(
        <Button 
            variant="outline"
            onClick={handleClick}
            className="rounded-2xl md:rounded-3xl border-1 border-gray-500"
        >
            {buttonText}
        </Button>
    )
};

const ButtonContact = () => {
    const navigate = useNavigate();

    const handleClick = () => {        
        navigate("/contact-us");            
    }

    return(
        <Button 
            variant="outline"
            onClick={handleClick}
            className="rounded-3xl border-1 border-gray-500 bg-[#00ED64] hover:rounded-xl hover:bg-[#00ED64]"
        >
            Contact Us
        </Button>
    )
};

export default function Header() {
    /* authenticate user with AuthContext */ 
    const { isLoggedIn } = useAuth();       

    return (
        <header className="fixed top-0 left-0 right-0 z-50 flex bg-background w-full py-4 md:px-2 justify-between h-24">
            
            <div className='flex-none w-36 sm:w-40 ml-4'>
                <Link to="/"> 
                    <img src={logo} alt='header-logo'/> 
                </Link>
            </div>     

            <div className="flex gap-2 mr-4">
                <div className='hidden md:block'>
                    <ButtonHeader buttonText={"Database"} buttonRoute={"/database"}/>
                </div>

                <div className='hidden md:block'>
                    <ButtonHeader buttonText={"Intelligence"} buttonRoute={"/ai"}/>
                </div> 

                <div className='hidden md:block'>
                    <ButtonHeader buttonText={"Company"} buttonRoute={"/company"}/>
                </div> 

                { isLoggedIn ? (
                    <ButtonUser buttonText={"You"} buttonRoute={"/you"}/>
                ): (
                    <ButtonUser buttonText={"Get started"} buttonRoute={"/login"}/>
                )}
                         
                
                <div className='hidden md:block flex-none'> 
                    <ButtonContact/>
                </div>
                
                <div className='p-0 md:hidden '>
                    <ButtonSheet/>
                </div>
            </div>
        
        </header>
    );
}