import { Card, CardContent } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

const CardProperty = ({ addressInfo, isLoading, onClick }) => {
    return (
      <Card 
        className="w-full rounded-xl max-w-80 h-32 cursor-pointer rounded-lg justify-between bg-green-200 border-1 border-gray-500 hover:rounded-3xl "
        onClick={onClick}
      >
        <CardContent className="p-0 h-full flex flex-col text-left">
          {isLoading ? (
            <>
              <div className='text-lg mx-8'>
                <Skeleton className="h-6 w-full my-1" />
                <Skeleton className="h-6 w-full my-1" />
                <Skeleton className="h-6 w-full my-1" />
              </div>              
            </>
          ) : (
            <>
              <div className='text-lg mx-8'>
                <p className="my-1">{addressInfo.street_number} {addressInfo.route}</p>
                <p className="my-1">{addressInfo.locality}</p>
                <p className="my-1">{addressInfo.state} {addressInfo.zipcode}</p>
              </div>              
            </>
          )}
        </CardContent>
      </Card>
    );
};

export { CardProperty };