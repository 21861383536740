import React, { useState, useEffect, } from 'react';

import { CardProperty } from './CardProperty';
import VisualizationCard_v3 from '../database-demo/visualization-card/VisualizationCard_v3';
import { CustomOverlay } from '../components/overlays/Overlays'; 

import './Recommendation.css';
import '../overlay/overlay.css';

const urlBackendRecommendation= process.env.REACT_APP_BACKEND_DOMAIN + "/api/recommendation/"; 

const Recommendation = () => {
    const [properties, setProperties] = useState([]);
    const [error, setError] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null); 
    const [isLoading, setIsLoading] = useState(true);
    // open Custom Overlay 
    const [isOpen, setIsOpen] = useState(false);   

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const response = await fetch(urlBackendRecommendation);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();                
                setProperties(data);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
                console.error('Error fetching random properties:', error);
            }
        };

        fetchProperties();
    }, []);

    const handleVisualization = (property) => {
        setSelectedProperty(property);        
        setIsOpen(true);
    };

    return (        
        <div className='max-w-screen-xl'>

          <div className="w-full my-8 text-center"> 
              <h3> Recommendations </h3>
          </div>
          
          <div className=''>
              {error && <p>Error: {error}</p>}
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">                            
            {isLoading 
                ? Array(6).fill().map((_, index) => <CardProperty key={index} isLoading={true} />)
                : properties.slice(0, 6).map(property => (
                    <CardProperty
                      className="col-span-1"
                      key={property.propertyID}
                      addressInfo={property.addressInfo}
                      isLoading={false}
                      onClick={() => handleVisualization(property)}
                    />
                  )) 
            }                
          </div>

          <CustomOverlay isOpen={isOpen} onClose={() => setIsOpen(false)}>
              {selectedProperty && (
                  <VisualizationCard_v3
                      dataJSON={selectedProperty}
                  />
              )}
          </CustomOverlay>

        </div>
    );
};

export default Recommendation;