import { useState, useMemo, useCallback } from 'react';

import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Separator } from "@/components/ui/separator";

import { IoCloseCircleOutline } from "react-icons/io5";
import { TbPdf } from "react-icons/tb";

const PDFPreview = ({ docLink, onClose }) => (
    <div className="h-full w-full">
        <div className="flex flex-row p-2">
            <div className="mr-4 cursor-pointer" onClick={onClose}>
                <IoCloseCircleOutline size={24} />
            </div>
        </div>
        <iframe 
            src={docLink}
            title="PDF Preview"
            className="h-[80svh] w-full rounded-3xl p-1"
        />
    </div>
);

const MyAudioPlayer = ({ srcAudio }) => (
    <div className="text-center w-full">
        <p className="text-lg">Listen to the story about the property.</p>
        <audio controls>
            <source                 
                src={srcAudio}
            />            
        </audio>
    </div>
);

const ButtonDocs = ({ doc, setShowPreview, setDocLink }) => {     
    const handlePreview = () => {
        setShowPreview(true);
        setDocLink(doc.document_link_presigned)
    };
    const formattedDate = new Date(doc.timestamp).toISOString().split('T')[0];

    return (
        <div>
            <Button 
                variant="outline" 
                className="ml-2 w-80 text-center"
                onClick={handlePreview}
            >
                <span className="inline-flex items-center ml-1">
                <TbPdf size={20} className="inline mr-1 flex-none"/>
                {doc.type}-{formattedDate}
                </span>                
            </Button>
        </div>
    )
}

const DocumentColumn = ({ data, srcAudio }) => {
    const [showPreview, setShowPreview] = useState(false);
    const [docLink, setDocLink] = useState(null);

    // Memoize the button list to prevent re-renders when showPreview changes
    const buttonDocsList = useMemo(() => (
        data ? (
            data.data.map((doc, index) => (
                <ButtonDocs 
                    key={index} 
                    doc={doc} 
                    setShowPreview={setShowPreview} 
                    setDocLink={setDocLink} 
                />
            ))
        ) : (
            Array.from({ length: 5 }).map((_, index) => (
                <Skeleton key={index} className="h-10 w-full mb-4 rounded-lg" />
            ))
        )
    ), [data]);

    // Close preview function to avoid recreating on every render
    const handleClosePreview = useCallback(() => setShowPreview(false), []);
    
    if (showPreview) {
        return (
            <PDFPreview docLink={docLink} onClose={handleClosePreview} />
        )
    } else {
        return (
            // <div className="flex flex-col h-[80svh]">
            <div className="flex flex-col h-[80svh]">
                {srcAudio && (                    
                    <div className="flex-none py-4">
                        <MyAudioPlayer srcAudio={srcAudio}/>                        
                    </div>
                )}
                {srcAudio && (
                    <Separator className="my-4" />
                )}
                
                <p className="text-center p-4 text-lg"> Documents </p>
                <div className="flex-1 overflow-y-auto w-full">                        
                    <div className="grid grid-cols-1 gap-1 w-full">
                        {buttonDocsList}
                    </div>
                </div>
            </div>
        )
    }
};

export { PDFPreview, DocumentColumn };