import { useAuth } from '../user-auth/AuthContext';
import Logout from '../user-auth/Logout'; 
import LoginOverlay from '@/user-auth/login/LoginOverlay';
import GetSavedProperties from './GetSavedProperties'; 

import DatabaseSearch from '@/database-demo/DatabaseSearch';
import Recommendation from '@/recommendation/Recommendation';
import { ExplorePlans } from '@/database/v1_1/PlanCards';


import './You.css';

export default function You () {
    const { isLoggedIn } = useAuth(); 

    return (        
        <div className='p-4'>
            {!isLoggedIn && (
                <LoginOverlay/>
            )}
            <div className='my-16'>
                <DatabaseSearch/>
            </div>

            <div className='my-16'>
                <Recommendation/>
            </div>

            <div className='my-16'>
                <GetSavedProperties />
            </div>
            
            <div className='max-w-screen-xl my-16'>
                <div className='text-center my-8'>
                    <h3>Explore Plans</h3>
                </div>
                <ExplorePlans/>
            </div>

            <div className='my-16'>
                <Logout/>
            </div>
            
            
        </div> 
    );
}