import DatabaseSearch from "./DatabaseSearch";
import Recommendation from "@/recommendation/Recommendation";

export default function DatabaseDemo () {
    return(
        <div>
            <DatabaseSearch/>
            <Recommendation/>
        </div>
    )
}